.modifyScroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.modifyScroll::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 20px;
}

.modifyScroll {
    scrollbar-width: thin;
    scrollbar-color: rgb(216, 216, 216) transparent;
}

.popoverScroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.popoverScroll::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 20px;
}

.popoverScroll {
    scrollbar-width: thin;
    scrollbar-color: rgb(216, 216, 216) transparent;
}
